import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/questy-targi-ehandlu-s.png";

const Targiehandlu = ({ data }) => {
  return (
    <Artykul
      title="Questy na Targach E-Handlu 2018 w Krakowie"
      keywords={["questy na targach e handlu"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Questy na Targach E-Handlu 2018 w Krakowie"
      metaTitle="Questy na Targach E-Handlu 2018 w Krakowie"
      metaDescription="Questy na Targach E-Handlu ✅ Targi E-Handlu 2018 w Krakowie • EXPO Kraków • Questy - dostawcy oprogramowania dla biznesu"
    >
      <br />

      <p>
        25 kwietnia 2018 braliśmy udział w Targach E-Handlu, zorganizowanych w
        tym roku w EXPO Kraków. Był to bardzo intensywny dzień.
        Przeprowadziliśmy mnóstwo naprawdę inspirujących rozmów, a także
        wymieniliśmy się dawką praktycznej wiedzy z odwiedzającymi. Dziękujemy
        za wspólnie spędzony czas i aktywny udział w naszych konkursach.
        Zapraszamy do obejrzenia relacji, którą dla Was przygotowaliśmy!
      </p>
      <br />
      <br />
      <center>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/JlDrANzfnVE"
          frameborder="0"
          allow="accelerometer; autoplay; 
      encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </center>
    </Artykul>
  );
};

export default Targiehandlu;
